import {createModuleInstance} from "./axios";

const moduleType = 'auth'

const authInstance = createModuleInstance(moduleType);


export const login = async (data) => {
    
    return authInstance.post('/login', data);
}

export const logout = async (data) => {
    return authInstance.post('/logout', data);
}

export const verifyToken = async () => {
    return authInstance.get('/verify-token')
}