// import axios from "axios";
import { useDispatch } from "react-redux";
import { verifyToken } from "../api/authApi";

// const verifyToken = async () => {
//   try {
//     const response = await axios.get("http://localhost:3001/api/v1/auth/verify-token",
//       {
//         headers: {
//           "Content-Type" : "application/json",
//           "Authorization" : "Bearer " + localStorage.getItem("authToken")
//         }
//       })

//       console.log(response)
//       return response;
//   } catch (error) {
//     console.log(error)
//   }

// }
const checkAuth = async () => {

  const TOKEN = localStorage.getItem("authToken");
  const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"]
  const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r))
  if (!TOKEN && !isPublicPage) {
    window.location.href = '/login';
    return;
  }
  else if (isPublicPage && !TOKEN) {
    return;
  }

  try {
    const result = await verifyToken();
    if (result.status === 200) {
      return TOKEN;
    }
  } catch (error) {
    alert(error)
    // localStorage.removeItem("authToken");
    // window.location.href = "/login"
  }

}

export default checkAuth