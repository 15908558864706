// src/api/axios.js
import axios from 'axios';

const BASE_URL = 'https://devbackend.rgsgroceries.com'
const LOCAL_BASE_URL = 'http://localhost:3001'
// const LOCAL_BASE_URL = "https://dac4-2401-4900-51cb-3197-20d-42a7-d10d-d686.ngrok-free.app"

const LOCAL_APP_URL = `${LOCAL_BASE_URL}/api/v1`
const APP_URL = `${BASE_URL}/api/v1`





const instance = axios.create({
  // baseURL: LOCAL_APP_URL, // Use environment variables for API base URL
  baseURL: APP_URL, // Use environment variables for API base URL
  headers: { 
    "Content-Type" : "application/json",
    "Authorization" : "Bearer " + localStorage.getItem("authToken")
  }
});

export const createModuleInstance = (moduleRoute) => {
  return axios.create({
    // baseURL: `${LOCAL_APP_URL}/${moduleRoute}`,
    baseURL: `${APP_URL}/${moduleRoute}`,
    timeout: 5000,
    headers: { 
      "Content-Type" : "application/json",
      "Authorization" : "Bearer " + localStorage.getItem("authToken")
    }
  });
};

export default instance;
