import {createModuleInstance} from "./axios";

const moduleType = 'dynamicoptions'

const dynamicOptions = createModuleInstance(moduleType);


// fetch all products
export const fetchAllDynamicOptions = async (optionType) => {
    return dynamicOptions.get('/getalloptions', {params: {optionType: optionType}});
}

// create new product
export const createDynamicOption = async (data, optionType) => {
    console.log(data, optionType)
    return dynamicOptions.post('/create', data, {params: {optionType: optionType}});
}

// fetch single product
// export const fetchSingleDynamicOption = async (id) => {
//     return dynamicOptions.get(`/get-single/${id}`)
// }

// update a product
export const updateDynamicOption = async (id, productData) => {
    return dynamicOptions.patch(`/updateOption`,{params: {id: id}}, productData);
}

// delete a product
export const deleteDynamicOption = async (optionId) => {
    return dynamicOptions.delete(`/deleteOption`, {data:{optionId: optionId}});
}