import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import productSlice from '../features/products/productSlice'
import minimalProductSlice from '../features/products/minimalProductSlice'
import storeSlice from '../features/store/storeSlice'
import dynamicOptionsSlice from '../features/dynamicoptions/dynamicOptionSlice'
import authSlice from '../features/user/authSlice'


const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  // lead: leadsSlice,
  completeProduct: productSlice,
  minimalProduct: minimalProductSlice,
  store: storeSlice,
  dynamicOption: dynamicOptionsSlice,
  auth: authSlice
}

export default configureStore({
  reducer: combinedReducer,
})