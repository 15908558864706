import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {fetchAllMinimalProducts, createNewMinimalProduct, fetchSingleMinimalProduct, deleteMinimalProduct, updateMinimalProduct, fetchAllMinimalProductsPageNoWise} from '../../api/minimalProductApi';
import { showNotification } from '../common/headerSlice';


// CRUD Thunks

// fetch all minimal products
export const fetchAlMinimallProductsThunk = createAsyncThunk(
    'products/fetchAllMinimalProducts',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchAllMinimalProducts();
            console.log(response.data)
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to fetch products");
        }
    }
);

// fetch minimal product page wise
export const fetchMinimalProductPageWiseThunk = createAsyncThunk(
    'minimal-products/get-page-wise',
    async ( constraints, {rejectWithValue}) => {
        try{
            const {pageNo, limit, searchQuery, sort} = constraints;
            const response = await fetchAllMinimalProductsPageNoWise(pageNo, limit, searchQuery, sort);
            return response.data;
        }
        catch(error){
            return rejectWithValue(error.response?.data?.message || "failed to fetch products");
        }
    }
)


// create new minimal product
export const createNewMinimalProductThunk = createAsyncThunk(
    'products/createProduct',
    async (productData, { rejectWithValue }) => {
        try {
            const response = await createNewMinimalProduct(productData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || "failed to create a minimal product");
        }
    }
);

// fetch single minimal product
export const fetchSingleMinimalProductThunk = createAsyncThunk(
    'products/fetchSingleMinimalProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await fetchSingleMinimalProduct(productId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to fetch minimal products")
        }
    }
);

// delete a minimal product
export const deleteMinimalProductThunk = createAsyncThunk(
    'products/deleteMinimalProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await deleteMinimalProduct(productId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to delete a minimal product");
        }
    }
);

// update a product
export const updateMinimalProductThunk = createAsyncThunk(
    'products/updateminimalProduct',
    async (data, { rejectWithValue }) => {
        const {productId, updatedData} = data;
        console.log(productId, updatedData)
        try {
            const response = await updateMinimalProduct(productId, updatedData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to update minimal product")
        }
    }
);



const minimalProductSlice = createSlice({
    name: 'minimalProducts',
    initialState: {
        minimalProducts: [],
        pagination: { totalPages: 1, currentPage: 1 }, // Stores pagination info
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle fetch
            .addCase(fetchAlMinimallProductsThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAlMinimallProductsThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.minimalProducts = action.payload.data;
            })
            .addCase(fetchAlMinimallProductsThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(fetchMinimalProductPageWiseThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMinimalProductPageWiseThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.minimalProducts = action.payload.data.data;
                state.pagination.totalPages = action.payload.data.pagination.totalPages;
                state.pagination.currentPage = action.payload.data.pagination.currentPage;
                console.log(action.payload.data.data)
            })
            .addCase(fetchMinimalProductPageWiseThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error?.response?.data?.message || "failed to fetch";
            })

            // Handle create
            .addCase(createNewMinimalProductThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(createNewMinimalProductThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.minimalProducts.push(action.payload.data);
                console.log(action.payload)
                showNotification({ message: action.payload.message, status: 1 });
            })
            .addCase(createNewMinimalProductThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                showNotification({ message: action.payload.message, status: 0 });
            })

            // Handle update
            .addCase(updateMinimalProductThunk.pending, (state) => {
                state.loading = true;
                console.log("updating...")
            })
            .addCase(updateMinimalProductThunk.fulfilled, (state, action) => {
                console.log("updated...")

                state.loading = false;
                const index = state.minimalProducts.findIndex((minimal_product) => minimal_product._id === action.payload._id);
                if (index !== -1) {
                    state.minimalProducts[index] = action.payload;
                }
            })
            .addCase(updateMinimalProductThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Handle delete
            .addCase(deleteMinimalProductThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteMinimalProductThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.minimalProducts = state.minimalProducts.filter((minimal_product) => minimal_product._id !== action.payload.data._id);
            })
            .addCase(deleteMinimalProductThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

    },
});


export default minimalProductSlice.reducer;
