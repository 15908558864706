import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {createDynamicOption, fetchAllDynamicOptions, deleteDynamicOption, updateDynamicOption} from '../../api/dynamicOptionApi';
import { showNotification } from '../common/headerSlice';


// CRUD Thunks

// fetch all minimal products
export const fetchAllDynamicOptionsThunk = createAsyncThunk(
    'dynamic-options/fetch-all',
    async (optionType, { rejectWithValue }) => {
        try {
            const response = await fetchAllDynamicOptions(optionType);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to fetch dynamic options");
        }
    }
);


// create new minimal product
export const createDynamicOptionThunk = createAsyncThunk(
    'dynamic-options/create',
    async (categoryDetails, { rejectWithValue }) => {
        try {
            const {categoryObj, optionType} = categoryDetails;
            const response = await createDynamicOption(categoryObj, optionType);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || "failed to create a dynamic option");
        }
    }
);

// fetch single minimal product
// export const fetchSingleDynamicOption = createAsyncThunk(
//     'products/fetchSingleMinimalProduct',
//     async (productId, { rejectWithValue }) => {
//         try {
//             const response = await fetchSingleMinimalProduct(productId);
//             return response.data;
//         } catch (error) {
//             return rejectWithValue(error.response?.data?.message || "failed to fetch minimal products")
//         }
//     }
// );

// delete a minimal product
export const deleteDynamicOptionThunk = createAsyncThunk(
    'dynamic-options/delete',
    async (optionId, { rejectWithValue }) => {
        try {
            const response = await deleteDynamicOption(optionId);
            console.log(response.data)
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to delete option");
        }
    }
);

// update a product
export const updateDynamicOptionThunk = createAsyncThunk(
    'dynamic-options/update',
    async ({productId, updatedData}, { rejectWithValue }) => {
        console.log(productId, updatedData)
        try {
            const response = await updateDynamicOption(productId, updatedData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to update dynamic option")
        }
    }
);


const dynamicOptionsSlice = createSlice({
    name: 'dynamicOption',
    initialState: {
        dynamicOptions: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle fetch
            .addCase(fetchAllDynamicOptionsThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllDynamicOptionsThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.dynamicOptions = action.payload.data;
            })
            .addCase(fetchAllDynamicOptionsThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Handle create
            .addCase(createDynamicOptionThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(createDynamicOptionThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.dynamicOptions.push(action.payload.data);
            })
            .addCase(createDynamicOptionThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Handle update
            .addCase(updateDynamicOptionThunk.pending, (state) => {
                state.loading = true;
                console.log("updating...")
            })
            .addCase(updateDynamicOptionThunk.fulfilled, (state, action) => {
                console.log("updated...")

                state.loading = false;
                const index = state.dynamicOptions.findIndex((item) => item._id === action.payload._id);
                if (index !== -1) {
                    state.dynamicOptions[index] = action.payload;
                }
            })
            .addCase(updateDynamicOptionThunk.rejected, (state, action) => {
                console.log("failed...")
                state.loading = false;
                state.error = action.payload;
            })

            // Handle delete
            .addCase(deleteDynamicOptionThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteDynamicOptionThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.dynamicOptions = state.dynamicOptions.filter((item) => item._id !== action.payload.data._id);
            })
            .addCase(deleteDynamicOptionThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

    },
});


export default dynamicOptionsSlice.reducer;
