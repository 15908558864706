import { createModuleInstance } from "../api/axios";

const moduleType = 'minimal-product-info'

// const APP_URI = `http://localhost:3001/api/v1/${modutleType}`

const minimalProductInstance = createModuleInstance(moduleType);


// fetch all products
export const fetchAllMinimalProducts = async () => {
    return minimalProductInstance.get('/get-all');
}

// fetch all products pageNo wise
export const fetchAllMinimalProductsPageNoWise = async (pageNo, limit, search, sortOption) => {
    return minimalProductInstance.get(`/get-page-wise`, {params: {page: pageNo, limit: limit, search, sortOption}})
}

// create new product
export const createNewMinimalProduct = async (productData) => {
    return minimalProductInstance.post('/create', productData);
}

// fetch single product
export const fetchSingleMinimalProduct = async (id) => {
    return minimalProductInstance.get(`/get-single/${id}`)
}

// update a product
export const updateMinimalProduct = async (id, productData) => {
    return minimalProductInstance.patch(`/single-update`, productData, { params: { id: id } });
}

// delete a product
export const deleteMinimalProduct = async (id) => {
    return minimalProductInstance.delete(`/delete`, { params: { id: id } });
}

